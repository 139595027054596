<template>
    <div class="security">
        <div id="Content">
            <div class="shadow"></div>
            <div class="content-inner">
              <h1 style="visibility:hidden; height: 0px; margin: 0">Security</h1>
                <h2>SSL</h2>
                <p>INCREDEVENT uses SSL. Perhaps you've heard that term before, but you don't know exactly what it is. Well, here's a description for you.</p>
                <p>The HTTPS uniform resource identifier (URI) has identical syntax to the standard HTTP, aside from its token. However, HTTPS signals the browser
                    to use an added encryption layer of SSL/TLS to protect the traffic. SSL/TLS is especially suited for HTTP, since it can provide some protection
                    even if only one side of the communication is authenticated. This is the case with HTTP transactions over the Internet, where typically only the
                    server is authenticated (by the client examining the server's certificate).
                </p>
                <p>HTTPS creates a secure channel over an insecure network. This ensures reasonable protection from eavesdroppers and man-in-the-middle attacks, provided 
                    that adequate cipher suites are used and that the server certificate is verified and trusted.</p>
                <p>Because HTTPS piggybacks HTTP entirely on top of TLS, the entirety of the underlying HTTP protocol can be encrypted. This includes the request URL 
                    (which particular web page was requested), query parameters, headers, and cookies (which often contain identity information about the user).</p>
                <p>Web browsers know how to trust HTTPS websites based on certificate authorities that come pre-installed in their software. Certificate 
                    authorities (such as Symantec, Comodo, GoDaddy and GlobalSign) are in this way being trusted by web browser creators to provide valid 
                    certificates. Therefore, a user should trust an HTTPS connection to a website if and only if all of the following are true:
                    <ul>
                        <li>The user trusts that the browser software correctly implements HTTPS with correctly pre-installed certificate authorities.</li>
                        <li>The user trusts the certificate authority to vouch only for legitimate websites.</li>
                        <li>The website provides a valid certificate, which means it was signed by a trusted authority.</li>
                        <li>The certificate correctly identifies the website (e.g., when the browser visits "https://example.com", the received certificate 
                            is properly for "example.com" and not some other entity).</li>
                        <li>The user trusts that the protocol's encryption layer (SSL/TLS) is sufficiently secure against eavesdroppers.</li>
                    </ul>
                </p>
                <p>HTTPS is especially important over insecure networks (such as public Wi-Fi access points), as anyone on the same local network can packet-sniff 
                    and discover sensitive information not protected by HTTPS. Additionally, many free to use and even paid for WLAN networks engage in packet 
                    injection in order to serve their own ads on webpages. However, this can be exploited maliciously in many ways, such as injecting malware 
                    onto webpages and stealing users' private information.</p>
                <p>HTTPS is also very important for connections over the Tor anonymity network, as malicious Tor nodes can damage or alter the contents 
                    passing through them in an insecure fashion and inject malware into the connection. This is one reason why the Electronic Frontier Foundation 
                    and the Tor project started the development of HTTPS Everywhere, which is included in the Tor Browser Bundle.</p>
                <p>As more information is revealed about global mass surveillance and criminals stealing personal information, the use of HTTPS security on all 
                    websites is becoming increasingly important regardless of the type of Internet connection being used. While metadata about individual pages 
                    that a user visits is not sensitive, when combined, they can reveal a lot about the user and compromise the user's privacy.</p>
                <p>Deploying HTTPS also allows the use of HTTP/2 (or its predecessor, the now-deprecated protocol SPDY), that are new generations of HTTP, 
                    designed to reduce page load times and latency.</p>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
h2 {
  padding-top: 0;
}
ul {
  margin-top: 15px;
  padding-left: 40px

}

noscript {
  color: #ffffff;
}
#Content .content-inner {

  min-height: 80vh;

}
</style>

<script>
export default {
  name: "security",
  head() {
    return {
      title: { inner: "Security" },
      meta: [
        {
          name: "description",
          content:
            "Industry-leading event management software: Event Management, Ticket Sales & Event Directory. Read INCREDEVENT's security statement here."
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion"
        },
        {
          name: "author",
          content: "INCREDEVENT"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0"
        }
      ]
    };
  },
  created() {
    this.$store.commit("setTitle", "Security");
  }
};
</script>
